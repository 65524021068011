var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('b-card-header',[_c('b',{staticClass:"h1"},[_vm._v("Basic information")])]),_c('b-card-body',[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('b-row',[_c('b-col',{attrs:{"md":"8"}},[_c('h3',[_vm._v("Platform Page")]),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"*Name:","label-cols-md":"3"}},[_c('validation-provider',{attrs:{"name":"Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Name"},on:{"input":function($event){return _vm.generateSlug(_vm.model.name)}},model:{value:(_vm.model.name),callback:function ($$v) {_vm.$set(_vm.model, "name", $$v)},expression:"model.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"*Title:","label-cols-md":"3"}},[_c('validation-provider',{attrs:{"name":"Title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Title"},model:{value:(_vm.model.title),callback:function ($$v) {_vm.$set(_vm.model, "title", $$v)},expression:"model.title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"*Slug:","label-cols-md":"3"}},[_c('validation-provider',{attrs:{"name":"Slug","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Slug"},model:{value:(_vm.model.slug),callback:function ($$v) {_vm.$set(_vm.model, "slug", $$v)},expression:"model.slug"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"*Group Page:","label-cols-md":"3"}},[_c('validation-provider',{attrs:{"name":"Group Page","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.groupPageOptions)?_c('v-select',{attrs:{"label":"name","reduce":function (x) { return x.id; },"options":_vm.groupPageOptions,"state":errors.length > 0 ? false : null},model:{value:(_vm.model.platform_group_page_id),callback:function ($$v) {_vm.$set(_vm.model, "platform_group_page_id", $$v)},expression:"model.platform_group_page_id"}}):_vm._e(),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"*Component category:","label-cols-md":"3"}},[_c('validation-provider',{attrs:{"name":"Component category","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.categoryOptions)?_c('v-select',{attrs:{"label":"name","reduce":function (x) { return x.id; },"options":_vm.categoryOptions,"state":errors.length > 0 ? false : null},model:{value:(_vm.model.component_categories_id),callback:function ($$v) {_vm.$set(_vm.model, "component_categories_id", $$v)},expression:"model.component_categories_id"}}):_vm._e(),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"*Position:","label-cols-md":"3"}},[_c('validation-provider',{attrs:{"name":"Position","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","state":errors.length > 0 ? false : null,"placeholder":"Position"},model:{value:(_vm.model.position),callback:function ($$v) {_vm.$set(_vm.model, "position", $$v)},expression:"model.position"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"*Is active:","label-cols-md":"3"}},[_c('validation-provider',{attrs:{"name":"Is active","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"text","reduce":function (x) { return x.value; },"options":_vm.activeOptions,"state":errors.length > 0 ? false : null},model:{value:(_vm.model.is_active),callback:function ($$v) {_vm.$set(_vm.model, "is_active", $$v)},expression:"model.is_active"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"*Type:","label-cols-md":"3"}},[_c('validation-provider',{attrs:{"name":"Type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"text","reduce":function (x) { return x.value; },"options":_vm.typeOptions,"state":errors.length > 0 ? false : null},model:{value:(_vm.model.type),callback:function ($$v) {_vm.$set(_vm.model, "type", $$v)},expression:"model.type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"SEO meta:","label-cols-md":"3"}},[_c('validation-provider',{attrs:{"name":"SEO meta","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"placeholder":"SEO meta","state":errors.length > 0 ? false : null},model:{value:(_vm.model.seo_meta),callback:function ($$v) {_vm.$set(_vm.model, "seo_meta", $$v)},expression:"model.seo_meta"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"*Global Style:","label-cols-md":"3"}},[_c('validation-provider',{attrs:{"name":"Global Style","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"content","options":_vm.snowOption,"state":errors.length > 0 ? false : null},model:{value:(_vm.model.global_style),callback:function ($$v) {_vm.$set(_vm.model, "global_style", $$v)},expression:"model.global_style"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('div',{staticClass:" align-items-center justify-content-center"},[_c('h3',[_vm._v("Avatar")]),_c('b-aspect',{staticClass:"position-relative",attrs:{"aspect":"1:1"}},[_c('b-img-lazy',{staticStyle:{"position":"absolute"},attrs:{"src":_vm.model.avatar,"fluid":"","thumbnail":"","rounded":""}}),_c('div',{staticClass:"fade-browse d-flex align-items-center justify-content-center w-100 h-100 rounded"},[_c('b-form-file',{staticClass:"w-100 h-100 d-flex align-items-center justify-content-center img-box",attrs:{"accept":".jpg, .png, .gif"},on:{"change":_vm.previewFiles}}),_c('b-button',{staticClass:"btn-icon rounded-circle btn-add",attrs:{"variant":"outline-primary"}},[_c('feather-icon',{attrs:{"icon":"PlusIcon"}})],1)],1)],1)],1)])],1),_c('b-col',{staticClass:"text-right",attrs:{"md":"12"}},[_c('b-button',{staticClass:"text-right",attrs:{"variant":"primary","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.validationForm($event)}}},[_vm._v(" Submit ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }