<template>
  <!-- eslint-disable vue/max-attributes-per-line -->
  <b-card>
    <b-card-header><b class="h1">Basic information</b></b-card-header>
    <b-card-body>
      <validation-observer ref="simpleRules">
        <b-form @submit.prevent>
          <b-row>
            <b-col md="8">
              <h3>Platform Page</h3>
              <b-row>
                <b-col md="12">
                  <b-form-group label="*Name:" label-cols-md="3">
                    <validation-provider
                      #default="{ errors }"
                      name="Name"
                      rules="required"
                    >
                      <b-form-input
                        v-model="model.name"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Name"
                        @input="generateSlug(model.name)"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group label="*Title:" label-cols-md="3">
                    <validation-provider
                      #default="{ errors }"
                      name="Title"
                      rules="required"
                    >
                      <b-form-input
                        v-model="model.title"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Title"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group label="*Slug:" label-cols-md="3">
                    <validation-provider
                      #default="{ errors }"
                      name="Slug"
                      rules="required"
                    >
                      <b-form-input
                        v-model="model.slug"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Slug"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group label="*Group Page:" label-cols-md="3">
                    <validation-provider
                      #default="{ errors }"
                      name="Group Page"
                      rules="required"
                    >
                      <v-select
                        v-if="groupPageOptions"
                        v-model="model.platform_group_page_id"
                        label="name"
                        :reduce="x => x.id"
                        :options="groupPageOptions"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group label="*Component category:" label-cols-md="3">
                    <validation-provider
                      #default="{ errors }"
                      name="Component category"
                      rules="required"
                    >
                      <v-select
                        v-if="categoryOptions"
                        v-model="model.component_categories_id"
                        label="name"
                        :reduce="x => x.id"
                        :options="categoryOptions"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group label="*Position:" label-cols-md="3">
                    <validation-provider
                      #default="{ errors }"
                      name="Position"
                      rules="required"
                    >
                      <b-form-input
                        v-model="model.position"
                        type="number"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Position"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group label="*Is active:" label-cols-md="3">
                    <validation-provider
                      #default="{ errors }"
                      name="Is active"
                      rules="required"
                    >
                      <v-select
                        v-model="model.is_active"
                        label="text"
                        :reduce="x => x.value"
                        :options="activeOptions"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group label="*Type:" label-cols-md="3">
                    <validation-provider
                      #default="{ errors }"
                      name="Type"
                      rules="required"
                    >
                      <v-select
                        v-model="model.type"
                        label="text"
                        :reduce="x => x.value"
                        :options="typeOptions"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- <b-col md="12">
                  <b-form-group label="Meta keyword:" label-cols-md="3">
                    <validation-provider
                      #default="{ errors }"
                      name="Meta keyword"
                      rules="required"
                    >
                      <b-form-input
                        v-model="model.meta_keyword"
                        placeholder="Meta keyword"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col> -->
                <!-- <b-col md="12">
                  <b-form-group label="Setting Component:" label-cols-md="3">
                    <validation-provider
                      #default="{ errors }"
                      name="Setting Component"
                      rules="required"
                    >
                      <quill-editor
                        v-model="model.setting_component"
                        placeholder="Setting Component"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col> -->
                <b-col md="12">
                  <b-form-group label="SEO meta:" label-cols-md="3">
                    <validation-provider
                      #default="{ errors }"
                      name="SEO meta"
                      rules="required"
                    >
                      <b-form-textarea
                        v-model="model.seo_meta"
                        placeholder="SEO meta"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- <b-col md="12">
                  <b-form-group label="Short content:" label-cols-md="3">
                    <b-form-textarea
                      v-model="model.short_content"
                      placeholder="Short content"
                    />
                  </b-form-group>
                </b-col> -->
                <b-col md="12">
                  <b-form-group label="*Global Style:" label-cols-md="3">
                    <validation-provider
                      #default="{ errors }"
                      name="Global Style"
                      rules="required"
                    >
                      <b-form-textarea
                        id="content"
                        v-model="model.global_style"
                        :options="snowOption"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
            <b-col md="4">
              <b-form-group>
                <div
                  class=" align-items-center justify-content-center"
                ><h3>Avatar</h3>
                  <b-aspect aspect="1:1" class="position-relative">
                    <b-img-lazy
                      :src="model.avatar"
                      fluid
                      thumbnail
                      rounded
                      style="position: absolute;"
                    />
                    <div
                      class="fade-browse d-flex align-items-center justify-content-center w-100 h-100 rounded"
                    >
                      <b-form-file
                        accept=".jpg, .png, .gif"
                        class="w-100 h-100 d-flex align-items-center justify-content-center img-box"
                        @change="previewFiles"
                      />
                      <b-button
                        variant="outline-primary"
                        class="btn-icon rounded-circle btn-add"
                      >
                        <feather-icon icon="PlusIcon" />
                      </b-button>
                    </div>
                  </b-aspect>
                </div>
              </b-form-group>
            </b-col>
            <!-- Submit -->
            <b-col md="12" class="text-right">
              <b-button
                variant="primary"
                type="submit"
                class="text-right"
                @click.prevent="validationForm"
              >
                Submit
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card-body>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCardHeader,
  BCardBody,
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BForm,
  BFormTextarea,
  BFormFile,
  BImgLazy,
  BAspect,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'
// import { quillEditor } from 'vue-quill-editor'
import Request from '@/plugins/service/index'
import general from '@/mixins/index'
import Config from '@/@config/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    BCardHeader,
    BCardBody,
    BForm,
    BFormTextarea,
    BFormFile,
    BImgLazy,
    BAspect,
    // quillEditor,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  mixins: [general],
  data() {
    return {
      required,
      model: {},
      snowOption: {
        theme: 'snow',
      },
      activeOptions: Config.activeOptions,
      typeOptions: Config.typeOptions,
      categoryOptions: null,
      groupPageOptions: null,
    }
  },
  created() {
    this.loadDetail()
    this.loadCategoryList()
    this.loadGroupPageList()
  },
  methods: {
    generateSlug(name) {
      this.model.slug = this.convertToSlug(name)
    },
    async loadDetail() {
      // const siteId = JSON.parse(localStorage.getItem('siteID')).id
      const templateId = JSON.parse(localStorage.getItem('templateID')).id
      try {
        const res = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/platform_page/${this.$route.params.id}?template_id=${templateId}&limit=1000`,
        )
        if (res.status === 200) {
          if (res.data.status) {
            this.model = await res.data.data
            this.loadCategoryDetail(this.model.component_categories_id)
            this.loadGroupPageDetail(this.model.platform_group_page_id)
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Login fail',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(this.showError(res.data.error, ',')),
              },
            })
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    async loadCategoryDetail(id) {
      const templateId = JSON.parse(localStorage.getItem('templateID')).id
      try {
        const res = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/component_category/${id}?template_id=${templateId}&limit=1000`,
        )
        if (res.status === 200) {
          if (res.data.status) {
            this.model.component_categories_id = res.data.data
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Login fail',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(this.showError(res.data.error, ',')),
              },
            })
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    async loadGroupPageDetail(id) {
      const templateId = JSON.parse(localStorage.getItem('templateID')).id
      try {
        const res = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/platform_group_page/${id}?template_id=${templateId}&limit=1000`,
        )
        if (res.status === 200) {
          if (res.data.status) {
            this.model.platform_group_page_id = res.data.data
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Login fail',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(this.showError(res.data.error, ',')),
              },
            })
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    async validationForm() {
      const params = {
        template_id: JSON.parse(localStorage.getItem('templateID')).id,
        title: this.model.title,
        name: this.model.name,
        position: Number(this.model.position),
        is_active: Number(this.model.is_active),
        type: Number(this.model.type),
        slug: this.model.slug,
        setting_component: JSON.stringify({}),
        seo_meta: this.model.seo_meta,
        global_style: this.model.global_style,
        component_categories_id:
          this.model.component_categories_id && this.model.component_categories_id.id
            ? this.model.component_categories_id.id
            : this.model.component_categories_id,
        platform_group_page_id:
          this.model.platform_group_page_id && this.model.platform_group_page_id.id
            ? this.model.platform_group_page_id.id
            : this.model.platform_group_page_id,
      }
      if (this.model.avatar !== null) {
        params.avatar = this.model.avatar
      }
      this.$refs.simpleRules.validate().then(async success => {
        if (success) {
          try {
            const res = await Request.put(
              this.$http,
              `${process.env.VUE_APP_API_URL}/platform_page/${this.$route.params.id}`,
              params,
            )
            if (res.status === 200) {
              if (res.data.status) {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Congratulation !',
                    icon: 'CheckCircleIcon',
                    variant: 'success',
                    text: 'Update Platform Page success',
                  },
                })
                this.$router.push('/platform-page/list')
              } else {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Login fail',
                    icon: 'AlertOctagonIcon',
                    variant: 'danger',
                    text: String(this.showError(res.data.error, ',')),
                  },
                })
              }
            }
          } catch (error) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(error),
              },
            })
          }
        }
      })
    },
    previewFiles(input) {
      const file = input.target.files[0]
      const reader = new FileReader()
      reader.onload = e => {
        this.model.avatar = e.target.result
      }
      reader.onerror = error => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
      reader.readAsDataURL(file)
    },
    async loadCategoryList() {
      const templateId = JSON.parse(localStorage.getItem('templateID')).id
      try {
        const res = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/component_categories?template_id=${templateId}&limit=1000`,
        )
        if (res.status === 200) {
          if (res.data.status) {
            this.categoryOptions = res.data.data.items
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Login fail',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(this.showError(res.data.error, ',')),
              },
            })
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    async loadGroupPageList() {
      const templateId = JSON.parse(localStorage.getItem('templateID')).id
      try {
        const res = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/platform_group_pages?template_id=${templateId}&limit=1000`,
        )
        if (res.status === 200) {
          if (res.data.status) {
            this.groupPageOptions = res.data.data.items
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Login fail',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(this.showError(res.data.error, ',')),
              },
            })
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/quill.scss';
@import '@core/scss/vue/pages/page-blog.scss';
</style>

<style lang="scss" scoped>
img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.fade-browse {
  border: 1px dashed var(--primary);
  position: relative;
  input:focus,
  button:focus {
    outline: unset;
    box-shadow: unset;
    border: unset;
  }
  .btn-add {
    position: absolute;
    z-index: 1;
  }
  .img-box {
    position: relative;
    z-index: 2;
    opacity: 0;
  }
}
</style>
